<template>
  <header class="current-header">
    <div class="container">
      <img class="logo" src="../../images/mtx-logo.png" alt="">
      <div
        class="tab-item pointer"
        :class="{ active: selected === '1' }"
        @click="tabHandler('1')"
      >
        首页
      </div>
      <div
        class="tab-item pointer"
        :class="{ active: selected === '2' }"
        @click="tabHandler('2')"
      >
        更新历程
      </div>
      <div class="tab-item pointer" @click="tabHandler('3')">登录</div>
    </div>
  </header>
</template>

<script>
import { useRouter } from "vue-router";
export default {
  props: {
    selected: {
      type: String,
      default: "1",
    },
  },
  setup(props) {
    const { resolve } = useRouter();

    function tabHandler(id) {
      const { href } = resolve({ path: id === "1" ? "/index" :  id === "2" ? "/update" : "" });
      if (id === "3") return window.open("//saas.mtx.net.cn/");
      if (id === props.selected) return;
      window.open(href);
    }
    return {
      tabHandler,
    };
  },
};
</script>

<style lang="less" scoped>
.current-header {
  position: fixed;
  z-index: 19;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 88px;
  background: #eef4ff;
  font-size: 20px;
  color: #111111;
  .container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 1080px;
    height: 100%;
    .logo {
      position: absolute;
      top: 24px;
      left: 0;
      width: 137px;
      height: 40px;
    }
    .tab-item {
      height: 28px;
      line-height: 28px;
      margin-left: 40px;
      &.active {
        position: relative;
        color: #0081ff;
        &::after {
          position: absolute;
          bottom: -5px;
          left: 50%;
          transform: translateX(-50%);
          content: "";
          width: 20px;
          height: 2px;
          border-radius: 1px;
          background: #0081ff;
        }
      }
      &:last-child {
        height: 40px;
        line-height: 39px;
        padding: 0 20px;
        border: 1px solid #999999;
        border-radius: 20px;
      }
    }
  }
}
</style>
