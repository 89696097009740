<template>
  <current-header selected="1"></current-header>
  <div class="space-fixed"></div>
  <div class="index-container">
    <div class="index-bg-color">
      <div class="banner-color"></div>
      <div class="sever-color"></div>
      <div class="point-color"></div>
      <div class="gzh-color"></div>
      <div class="auto-color"></div>
      <div class="pub-color"></div>
      <div class="setting-color"></div>
      <div class="minip-color"></div>
      <div class="other-color"></div>
      <div class="over-color"></div>
      <div class="bottom-color"></div> 
    </div>
    <div class="index-box-item">
      <div class="banner-box">
        <div class="title">
          <div class="logo">满天星</div>
          <div class="tip">微信生态流量变现系统</div>
        </div>
        <div class="swiper">
          <img class="item" :class="{'sidein': swiperType == 1,'sideout': swiperType == 2}" src="./images/swiper-1.png" alt="">
          <img class="item" :class="{'sidein': swiperType == 2,'sideout': swiperType == 3}" src="./images/swiper-2.png" alt="">
          <img class="item" :class="{'sidein': swiperType == 3,'sideout': swiperType == 1}" src="./images/swiper-3.png" alt="">
        </div>
        <img class="ani-1" src="./images/ani-1.png" alt="">
        <img class="ani-2" src="./images/ani-2.png" alt="">
        <img class="ani-3" :class="{'animation': show3}" src="./images/ani-3.png" alt="">
        <img class="ani-4" :class="{'animation': show4}" src="./images/ani-4.png" alt="">
        <img class="ani-5" src="./images/ani-5.png" alt="">
        <img class="ani-6" src="./images/ani-6.png" alt="">
        <img class="ani-7" src="./images/ani-7.png" alt="">
        <img class="ani-8" src="./images/ani-8.png" alt="">
        <img class="ani-9" :class="{'animation': show9}" src="./images/ani-9.png" alt="">
      </div>
      <div class="sever-box">
        <div class="title wow fadeInUp">满天星是基于行业明星产品的<br>一键式无门槛流量变现SaaS服务</div>
        <div class="sever-list clearfix">
          <div class="item wow fadeInUp">
            <img src="./images/sever-1.png" alt="">
            <div class="info">
              <p class="item-title">明星产品策略</p>
              <p class="item-tips">以行业优质案例为基础<br>经过考验的高留存高转化效果</p>
            </div>
          </div>
          <div class="item wow fadeInUp">
            <img src="./images/sever-2.png" alt="">
            <div class="info">
              <p class="item-title">自动化配置</p>
              <p class="item-tips">高度的自动化与优选策略<br>从开始到上线仅需10分钟</p>
            </div>
          </div>
          <div class="item wow fadeInUp">
            <img src="./images/sever-3.png" alt="">
            <div class="info">
              <p class="item-title">全面集成封装</p>
              <p class="item-tips">完整封装公众号配置、完整小程序、运营<br>功能策略、第三方服务集成与数据接口</p>
            </div>
          </div>
          <div class="item wow fadeInUp">
            <img src="./images/sever-4.png" alt="">
            <div class="info">
              <p class="item-title">分层营销宽度</p>
              <p class="item-tips">根据自身的营销投入情况<br>上线对应投入效果的营销能力</p>
            </div>
          </div>
          <div class="item wow fadeInUp">
            <img src="./images/sever-5.png" alt="">
            <div class="info">
              <p class="item-title">平台同步与合规</p>
              <p class="item-tips">完全同步外卖联盟官方推广规则<br>同时符合微信生态要求</p>
            </div>
          </div>
          <div class="item wow fadeInUp">
            <img src="./images/sever-6.png" alt="">
            <div class="info">
              <p class="item-title">稳定的服务</p>
              <p class="item-tips">依托上市企业<br>提供完善稳定的系统与客户服务</p>
            </div>
          </div>
        </div>
      </div>
      <div class="point-box">
        <div class="title wow fadeInUp">满天星生态赋能平台</div>
        <div class="subtitle wow fadeInUp">—— 外卖联盟流量变现体系亮点</div>
        <div class="point-list clearfix">
          <img class="wow fadeInUp" src="./images/point-1.png" alt="">
          <img class="wow fadeInUp" src="./images/point-2.png" alt="">
          <img class="wow fadeInUp" src="./images/point-3-v1.png" alt="">
          <img class="wow fadeInUp" src="./images/point-4.png" alt="">
          <img class="wow fadeInUp" src="./images/point-5.png" alt="">
          <img class="wow fadeInUp" src="./images/point-6.png" alt="">
        </div>
        <!-- <div class="point-video wow fadeInUp">
          <a class="play-btn" v-if="!playStatus" @click="playVideo" href="javascript:void(0)"></a>
          <video ref="video" id="" controls controlsList="nodownload" src="./images/mtx-video.mp4"></video>
        </div> -->
      </div>
      <div class="gzh-box">
        <div class="title wow fadeInUp">满天星生态赋能平台</div>
        <div class="subtitle wow fadeInUp">您可以<span>立刻从0到1拥有功能完备的外卖变现公众号，</span>当下即能上线运行。授权过程中可自动检查授权完整度，确保核心营销功能正确发布。</div>
        <img class="gzh-info wow fadeInUp" src="./images/gzh-info.png">
      </div>
      <div class="auto-box">
        <div class="title wow fadeInUp">自动接入平台推广</div>
        <div class="subtitle wow fadeInUp">无需申请配置等繁琐步骤，<span>自动为您接入平台推广红包链接，</span>覆盖所有外卖用户的需求，<span>完全同步平台官方的跟单与结算规则，</span>零成本且标准化。</div>
        <div class="info wow fadeInUp">
          <img class="wow fadeInUp" src="./images/auto-info.png">
          <div class="bg"></div>
        </div>
      </div>
      <div class="pub-box">
        <div class="title wow fadeInUp">高质量运营策略模版化发布</div>
        <div class="subtitle wow fadeInUp">将高质量运营策略全部<span>模版化，</span>只需动动手指便可以上线，免去繁复的策略配置与测试过程。</div>
        <div class="info wow fadeInUp">
          <img class="wow fadeInUp" src="./images/pub-info.png">
          <div class="bg"></div>
        </div>
      </div>
      <div class="setting-box">
        <div class="title wow fadeInUp">高价值营销配置运营</div>
        <div class="subtitle wow fadeInUp">经过我们反复衡量的策略/数值/前端表现组合，为公众号<span>带来极有吸引力的用户黏性与复购率，</span>不必再自行尝试与调优。</div>
        <div class="info wow fadeInUp">
          <img class="wow fadeInUp" src="./images/setting-info.png">
          <div class="bg"></div>
        </div>
      </div>
      <div class="minip-box">
        <div class="title wow fadeInUp">小程序零开发接入</div>
        <div class="subtitle wow fadeInUp">无需任何开发便能够拥有<span>微信官方认可</span>的配套小程序，省去自行研究与开发的时间与成本。</div>
        <div class="info wow fadeInUp">
          <img class="wow fadeInUp" src="./images/minp-info-1.png">
          <img class="wow fadeInUp" src="./images/minp-info-2.png">
          <div class="bg"></div>
        </div>
      </div>
      <div class="over-box">
        <p class="title">满天星官方服务顾问</p>
        <p class="subtitle">请使用微信APP扫描二维码<br>联系服务顾问</p>
        <div class="code-box"></div>
      </div>
      <div class="bottom-box">Copyright © 返利网 2006-2022, All Rights Reserved.
        <a href="https://beian.miit.gov.cn/" target="_blank" style='text-decoration:none; font-size:16px; color:#354F7F; font-weight: 200;'>沪ICP备17024454号-6</a>.</div>
    </div>
  </div>
  
</template>

<script>
import currentHeader from "@/components/current-header/current-header";
import {WOW} from 'wowjs'

export default {
  components: {
    currentHeader,
  },
  data () {
    return{
      swiperType:0,
      show3:false,
      show4:false,
      show9:false,
      playStatus:false,
    }
  },
  methods:{
    intervalSwiper () {
      setTimeout(() => {
        this.swiperType = 1;
      }, 10)
      setInterval(() => {
        if(this.swiperType == 3){
          this.swiperType = 1;
        } else {
          this.swiperType++;
        }
      }, 2400)
      setTimeout(() => {
        this.show3 = true;
      }, 1660)
      setTimeout(() => {
        this.show4 = true;
      }, 2170)
      setTimeout(() => {
        this.show9 = true;
      }, 2920)
    },
    playVideo () {
      this.playStatus = true;
      let video = document.querySelector("video");
      video.play();
      video.addEventListener('ended', function () { 
        this.playStatus = false;
        video.currentTime = 0;
      }, false);
    }
  },
  mounted () {
    this.intervalSwiper();
    this.$nextTick(() => { // 在dom渲染完后,再执行动画
      var wow = new WOW({
        offset:200,
      })
      wow.init()
    })
  }
  
}
</script>

<style lang="less" scoped>
  .position-center{
    position:absolute;
    left:50%;
    transform: translateX(-50%);
  }
  .space-fixed {
    height: 88px;
  }
  .index-container{
    position: relative;
  }
  .index-bg-color{
    position:relative;
    z-index: 0;
    .banner-color{
      width:100%;
      height:872px;
      background:#0081FF;
    }
    .sever-color{
      width:100%;
      height:1000px;
      background:#EEF4FF;
    }
    .point-color{
      width:100%;
      height:929px;
      background:#FFF;
    }
    .gzh-color{
      width:100%;
      height:899px;
      background:#0081FF;
    }
    .auto-color{
      width:100%;
      height:811px;
      background:#FFF;
    }
    .pub-color{
      width:100%;
      height:976px;
      background:#EEF4FF;
    }
    .setting-color{
      width:100%;
      height:1005px;
      background:#fff;
    }
    .minip-color{
      width:100%;
      height:970px;
      background:#EEF4FF;
    }
    .other-color{
      width:100%;
      height:528px;
      background:#FFF;
    }
    .over-color{
      width:100%;
      height:496px;
      background:#0081FF;
    }
    .bottom-color{
      width:100%;
      height:110px;
      background:#EEF4FF;
    }
  }
  .index-box-item{
    position: absolute;
    left:0;
    top:0;
    width:100%;
    .banner-box{
      .position-center();
      top:0;
      width:1208px;
      height:872px;
      background:url(./images/banner-info.png) no-repeat;
      background-size:100% auto;
      .title{
        position:absolute;
        top:70px;
        left:64px;
        height:84px;
        display: flex;
        .logo{
          margin-right:40px;
          line-height:84px;
          font-size:60px;
          font-weight:400;
          color:#FFF;
        }
        .tip{
          line-height:101px;
          font-size:38px;
          font-weight: 200;
          color:#FFF;
        }
      }
      .swiper{
        position: absolute;
        top:194px;
        left:60px;
        .item{
          position:absolute;
          top:50px;
          left:0;
          height:114px;
          opacity: 0;
          &.sidein{
            transition:all 0.33s linear 0.66s;
            top:0;
            opacity: 1;
          }
          &.sideout{
            transition:all 0.33s linear;
            top:-50px;
            opacity: 0;
          }
        }
      }
      .ani-1{
        position:absolute;
        top:587px;
        left:431px;
        width:214px;
        height:99px;
        border:0;
        transform: scale(0);
        transform-origin:0 100%;
        animation: aniscale 0.33s linear 1.08s forwards;
      }
      .ani-2{
        position:absolute;
        top:663px;
        left:326px;
        width:214px;
        height:99px;
        border:0;
        transform: scale(0);
        transform-origin:100% 100%;
        animation: aniscale 0.33s linear 0.91s forwards;
      }
      .ani-3{
        position:absolute;
        top:505px;
        left:206px;
        width:194px;
        height:119px;
        border:0;
        transform: scale(0);
        transform-origin:100% 100%;
        animation: aniscale 0.25s linear 1.41s forwards;
        &.animation{
          animation: interval 1.5s linear infinite;
        }
      }
      .ani-4{
        position:absolute;
        top:398px;
        left:421px;
        width:189px;
        height:116px;
        border:0;
        transform: scale(0);
        transform-origin:50% 100%;
        animation: aniscale 0.33s linear 1.84s forwards;
        &.animation{
          animation: interval 1.5s linear infinite;
        }
      }
      .ani-5{
        position:absolute;
        top:362px;
        left:566px;
        width:56px;
        height:70px;
        border:0;
        transform: scale(0);
        transform-origin:50% 50%;
        animation: aniscale 0.33s linear 2.01s forwards;
      }
      .ani-6{
        position:absolute;
        top:385px;
        left:639px;
        width:56px;
        height:33px;
        border:0;
        transform: scale(0);
        transform-origin:50% 50%;
        animation: aniscale 0.33s linear 2.01s forwards;
      }
      .ani-7{
        position:absolute;
        top:370px;
        left:523px;
        width:17px;
        height:17px;
        border:0;
        transform: scale(0);
        transform-origin:50% 50%;
        animation: aniscale 0.33s linear 2.34s forwards;
      }
      .ani-8{
        position:absolute;
        top:340px;
        left:640px;
        width:21px;
        height:21px;
        border:0;
        transform: scale(0);
        transform-origin:50% 50%;
        animation: aniscale 0.42s linear 2.42s forwards;
      }
      .ani-9{
        position:absolute;
        top:555px;
        left:709px;
        width:85px;
        height:84px;
        border:0;
        transform: scale(0);
        transform-origin:100% 100%;
        animation: aniscale 0.33s linear 2.59s forwards;
        &.animation{
          animation: interval 1.5s linear infinite;
        }
      }
    }
    .sever-box{
      .position-center();
      top:872px;
      width:1080px;
      height:1000px;
      .title{
        padding-top: 90px;
        margin-bottom:50px;
        line-height:80px;
        font-size:48px;
        color:#111;
        font-weight:500;
      }
      .sever-list{
        .item{
          float:left;
          display:flex;
          align-items: center;
          width:530px;
          height:190px;
          margin:0 20px 20px 0;
          border-radius:4px;
          box-sizing: border-box;
          padding-left:40px;
          background:#FFF;
          &:nth-child(2n){
            margin-right:0;
          }
          img{
            display:block;
            width:110px;
            height:110px;
            margin-right:40px;
          }
          .info{
            .item-title{
              margin-bottom:10px;
              height:40px;
              line-height:40px;
              font-size:28px;
              color:#354F7F;
            }
            .item-tips{
              line-height:32px;
              font-size:16px;
              color:#354F7F;
            }
          }
        }
      }
    }
    .point-box{
      .position-center();
      top:1872px;
      width:1080px;
      height:929px;
      .title{
        padding-top:90px;
        margin-bottom:30px;
        // height:67px;
        line-height:67px;
        font-size:48px;
        color:#111;
        font-weight:500;
      }
      .subtitle{
        margin-bottom:70px;
        height:45px;
        line-height:45px;
        font-size:32px;
        color:#111;
      }
      .point-list{
        height:580px;
        img{
          float: left;
          width:346px;
          height:270px;
          margin:0 20px 20px 0;
          &:nth-child(3n){
            margin-right:0;
          }
        }
      }
      .point-video{
        position:relative;
        margin-top:50px;
        width:1080px;
        height:608px;
        .play-btn{
          position:absolute;
          width:100%;
          height:100%;
          z-index: 2;
          background:url(./images/play-bg.png) no-repeat;
          background-size:100% auto;
        }
        video{
          position:absolute;
          width:100%;
          height:100%;
          z-index: 1;
        }
      }
    }
    .gzh-box{
      .position-center();
      top:2801px;
      width:1080px;
      height:1131px;
      .title{
        padding-top:90px;
        margin-bottom:21px;
        line-height:67px;
        font-size:48px;
        color:#FFF;
        font-weight:500;
      }
      .subtitle{
        line-height:40px;
        font-size:28px;
        color:#FFF;
        font-weight: 200;
        span{
          font-weight:400;
        }
      }
      .gzh-info{
        display:block;
        margin-top:55px;
        width:1080px;
        height:818px;
      }
    }
    .auto-box{
      .position-center();
      top:4062px;
      width:1080px;
      height:809px;
      .title{
        margin-bottom:21px;
        line-height:67px;
        font-size:48px;
        color:#111;
        font-weight:500;
      }
      .subtitle{
        line-height:40px;
        font-size:28px;
        color:#111;
        font-weight: 200;
        span{
          font-weight:400;
        }
      }
      .info{
        position:relative;
        margin-top:55px;
        width:1080px;
        height:586px;
        img{
          position: relative;
          display:block;
          width:100%;
          height:100%;
          z-index: 2;
        }
        .bg{
          position:absolute;
          top:90px;
          left:92px;
          width:1048px;
          height:561px;
          border-radius:10px;
          opacity: 0.6;
          background: #DCE9FF;
          z-index: 1;
        }
      }
    }
    .pub-box{
      .position-center();
      top:5066px;
      width:1080px;
      height:809px;
      .title{
        margin-bottom:21px;
        line-height:67px;
        font-size:48px;
        color:#111;
        font-weight:500;
      }
      .subtitle{
        line-height:40px;
        font-size:28px;
        color:#111;
        font-weight: 200;
        span{
          font-weight:400;
        }
      }
      .info{
        position:relative;
        margin-top:55px;
        width:1080px;
        height:586px;
        img{
          position: relative;
          display:block;
          width:100%;
          height:100%;
          z-index: 2;
        }
        .bg{
          position:absolute;
          top:90px;
          left:92px;
          width:1048px;
          height:561px;
          border-radius:10px;
          opacity: 0.6;
          background: #DCE9FF;
          z-index: 1;
        }
      }
    }
    .setting-box{
      .position-center();
      top:6070px;
      width:1080px;
      height:809px;
      .title{
        margin-bottom:21px;
        line-height:67px;
        font-size:48px;
        color:#111;
        font-weight:500;
      }
      .subtitle{
        line-height:40px;
        font-size:28px;
        color:#111;
        font-weight: 200;
        span{
          font-weight:400;
        }
      }
      .info{
        position:relative;
        margin-top:55px;
        width:1080px;
        height:586px;
        img{
          position: relative;
          display:block;
          width:100%;
          height:100%;
          z-index: 2;
        }
        .bg{
          position:absolute;
          top:90px;
          left:92px;
          width:1048px;
          height:561px;
          border-radius:10px;
          opacity: 0.6;
          background: #DCE9FF;
          z-index: 1;
        }
      }
    }
    .minip-box{
      .position-center();
      top:7074px;
      width:1080px;
      height:785px;
      .title{
        margin-bottom:21px;
        line-height:67px;
        font-size:48px;
        color:#111;
        font-weight:500;
      }
      .subtitle{
        line-height:40px;
        font-size:28px;
        color:#111;
        font-weight: 200;
        span{
          font-weight:400;
        }
      }
      .info{
        position:relative;
        display:flex;
        justify-content: space-between;
        margin-top:55px;
        width:1080px;
        height:602px;
        img{
          position: relative;
          display:block;
          height:100%;
          z-index: 2;
          &:nth-child(1){
            width:700px;
          }
          &:nth-child(2){
            width:339px;
          }
        }
        .bg{
          position:absolute;
          top:78px;
          left:93px;
          width:1048px;
          height:565px;
          border-radius:10px;
          opacity: 0.6;
          background: #DCE9FF;
          z-index: 1;
        }
      }
    }
    .over-box{
      .position-center();
      top:7990px;
      width:1160px;
      height:496px;
      background:url(./images/over-info.png) no-repeat;
      background-size:100% auto;
      .code-box {
        position: absolute;
        top: 110px;
        left: 38px;
        width: 281px;
        height: 279px;
        background: url("./images/code.jpeg") no-repeat;
        background-size: 100% 100%;
      }
      .title{
        position:absolute;
        top:108px;
        left:426px;
        line-height:59px;
        font-size:42px;
        color:#FFF;
        font-weight:500;
      }
      .subtitle{
        position:absolute;
        top:187px;
        left:426px;
        line-height:40px;
        font-size:28px;
        color:#FFF;
        font-weight:200;
      }
    }
    .bottom-box{
      .position-center();
      top:8486px;
      width:1080px;
      height:110px;
      text-align: center;
      line-height:110px;
      font-size:16px;
      color:#354F7F;
      font-weight: 200;
    }
  }
  @keyframes aniscale {
    0%{transform: scale(0);}
    100%{transform: scale(1);}
  }
  @keyframes interval {
    0%{transform: translateY(0);}
    50%{transform: translateY(5px);}
    100%{transform: translateY(0);}
  }
</style>